<template>
  <div id="campaignReviewPage" v-loading.fullscreen.lock="startingCampaign || fetchingData">
    <template v-if="!fetchingData">
      <!-- Campaign Steps -->
      <CampaignSteps v-if="campaign" :step="step" :campaign="campaign"></CampaignSteps>

      <!-- Campaign Summary Step -->
      <div class="reviewContent" v-if="campaign">
        <el-form>
          <el-card class="box-card" shadow="never">
            <div slot="header" class="clearfix">
              <span>General Info</span>
            </div>

            <!-- Start Type Info -->
            <el-row>
              <!-- Campaign Name -->
              <el-col :span="24">
                <div class="label">Campaign Name</div>
                <div class="value">{{ campaign.name }}</div>
              </el-col>

              <!-- Campaign Start Type -->
              <el-col :span="12" class="row-with-margin">
                <div class="label">When you want to start campaign?</div>
                <div class="value">{{ campaign.start_type == 'now' ? 'Start Immediately' : 'Start Later' }}</div>
              </el-col>

              <!-- If Start type is later, provide later date -->
              <el-col :span="12" v-if="campaign.start_type == 'now'" class="row-with-margin">
                <div class="label">Campaign will start at</div>
                <div class="value">{{ campaign.start_time }}</div>
              </el-col>
            </el-row>
          </el-card>

          <!-- UTM Tracking -->
          <el-card class="box-card" shadow="never">
            <div slot="header" class="clearfix">
              <span>UTM Tracking For Campaign</span>
            </div>
            <UtmTrackingInput :channelType="campaign.channel" :readOnly="true" ref="utmInput"></UtmTrackingInput>
          </el-card>

          <!-- Target Audience Info -->
          <div class="overviewCard border bg-white rounded-md" shadow="never">
            <!-- Card Header -->
            <div class="bg-gray-100 border-b p-2.5">TARGET AUDIANCE</div>
            <!-- Card Content -->
            <div>
              <div class="p-3">
                <div class="mb-1">INCLUDE AUDIENCE</div>
                <!-- Custom Audience -->
                <template v-if="targetAudiance.include.audiance_type == 'custom'">
                  <UserPropertyFilterComponent ref="userFilterSummary" v-bind:readOnly="true" v-bind:formattedPropertyFilter="targetAudiance.include.filter"></UserPropertyFilterComponent>
                </template>
                <!-- Segment Audiance -->
                <div class="formItemForDisplay" v-else-if="targetAudiance.include.audiance_type == 'segment'">
                  <span class="label"> Customers In Segment: </span>
                  {{ targetAudiance.include.segment.name + (targetAudiance.include.segment.is_deleted ? ' (Deleted)' : '') }}
                </div>
                <div v-else-if="targetAudiance.include.audiance_type == 'segment-v2'">
                  <template v-if="targetAudiance.include.segment_v2.segments && targetAudiance.include.segment_v2.segments.length > 0">
                    <div class="text-xs mt-2">Customers In Segment:</div>
                    <el-tag class="mr-2.5" type="info" size="small" effect="dark" :key="index" v-for="(segment, index) in targetAudiance.include.segment_v2.segments">
                      {{ segment.name }}
                    </el-tag>
                  </template>
                  <template v-if="targetAudiance.include.segment_v2.lists && targetAudiance.include.segment_v2.lists.length > 0">
                    <div class="text-xs mt-3">Customers In Static List:</div>
                    <el-tag class="mr-2.5" type="info" size="small" effect="dark" :key="index" v-for="(segment, index) in targetAudiance.include.segment_v2.lists">
                      {{ segment.name }}
                    </el-tag>
                  </template>
                </div>
                <!-- Static List Audience -->
                <div class="formItemForDisplay" v-else-if="targetAudiance.include.audiance_type == 'static_list'">
                  <span class="label"> Customers In Static List: </span>
                  {{ targetAudiance.include.static_list.name + (targetAudiance.include.static_list.is_deleted ? ' (Deleted)' : '') }}
                </div>
                <!-- All Customers Audience -->
                <template v-else> All Customers </template>
              </div>

              <div class="p-3 border-t" v-if="targetAudiance.exclude">
                <div class="mb-1">EXCLUDE AUDIENCE</div>
                <!-- Custom Audience -->
                <template v-if="targetAudiance.exclude.audiance_type == 'custom'">
                  <UserPropertyFilterComponent ref="userFilterSummary" v-bind:readOnly="true" v-bind:formattedPropertyFilter="targetAudiance.exclude.filter"></UserPropertyFilterComponent>
                </template>
                <!-- Segment Audience -->
                <div class="formItemForDisplay" v-else-if="targetAudiance.exclude.audiance_type == 'segment'">
                  <span class="label"> Customers In Segment: </span>
                  {{ targetAudiance.exclude.segment.name + (targetAudiance.exclude.segment.is_deleted ? ' (Deleted)' : '') }}
                </div>
                <div v-else-if="targetAudiance.exclude.audiance_type == 'segment-v2'">
                  <template v-if="targetAudiance.exclude.segment_v2.segments && targetAudiance.exclude.segment_v2.segments.length > 0">
                    <div class="text-xs mt-2">Customers In Segment:</div>
                    <el-tag class="mr-2.5" type="info" size="small" effect="dark" :key="index" v-for="(segment, index) in targetAudiance.exclude.segment_v2.segments">
                      {{ segment.name }}
                    </el-tag>
                  </template>
                  <template v-if="targetAudiance.exclude.segment_v2.lists && targetAudiance.exclude.segment_v2.lists.length > 0">
                    <div class="text-xs mt-3">Customers In Static List:</div>
                    <el-tag class="mr-2.5" type="info" size="small" effect="dark" :key="index" v-for="(segment, index) in targetAudiance.exclude.segment_v2.lists">
                      {{ segment.name }}
                    </el-tag>
                  </template>
                </div>
                <!-- Static List Audience -->
                <div class="formItemForDisplay" v-else-if="targetAudiance.exclude.audiance_type == 'static_list'">
                  <span class="label"> Customers In Static List: </span>
                  {{ targetAudiance.exclude.static_list.name + (targetAudiance.exclude.static_list.is_deleted ? ' (Deleted)' : '') }}
                </div>
                <!-- All Customers Audience -->
                <template v-else> All Customers </template>
              </div>
            </div>
          </div>
        </el-form>
      </div>

      <!-- Step Footer -->
      <CampaignFooter :step="step" :showPublish="true" v-if="campaign" :campaign="campaign" @onPublish="onPublish" @onPrev="onGoBack"></CampaignFooter>
      <!-- <el-row class="campaignStepFooter">
        <el-col :span="12">
          <el-button class="prevButton" type="warning" size="big" @click="onGoBack">
            <i class="el-icon-back"></i>
            &nbsp; Campaign Goals
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button class="nextBtn" type="primary" size="big" :loading="startingCampaign" @click="onPublish"> Launch Campaign </el-button>
        </el-col>
      </el-row> -->
    </template>
  </div>
</template>
<style lang="scss" src="./campaignReviewLaunch.scss"></style>

<script>
import campaignReviewLaunchComponent from './campaignReviewLaunchComponent';
export default campaignReviewLaunchComponent;
</script>
