import { getDetailsForReviewPage } from './../../services/campaignv1';
import UserPropertyFilterComponent from '@/components/chartFilters/userPropertyFilter/userPropertyFilter';
import UtmTrackingInput from '@/components/campaign/utmTracking/utmTrackingInput';
import CampaignSteps from '@/components/campaign/createCampaignSteps';
import { getSegmentList } from '@/services/sessionFilter';
import { getAllStaticSegments } from '@/services/staticSegment';
const moment = require('moment');
import { publishCampaign } from '@/services/campaignv1.js';
import CampaignFooter from '@/components/campaign/createCampaignFooter';

export default {
  name: 'campaignReviewLaunch',
  props: {},
  components: {
    UserPropertyFilterComponent,
    CampaignSteps,
    CampaignFooter,
    UtmTrackingInput
  },
  data() {
    return {
      step: 4,
      fetchingData: true,

      campaignId: null,
      campaign: null,

      targetAudiance: null,

      savingCampaign: false,
      startingCampaign: false
    };
  },
  mixins: [],

  methods: {
    async fetchDetails() {
      try {
        this.fetchingData = true;

        // Fetch Details For Review.
        let response = await getDetailsForReviewPage(this.campaignId);
        this.campaign = response.data.campaign;
        if (!this.campaign) {
          this.$router.replace({ name: '404' });
          return;
        }
        this.campaign.start_time = this.campaign.start_time ? moment(parseInt(this.campaign.start_time)).format('YYYY-MM-DD HH:mm A') : null;

        // Format on-action-events if found.
        if (this.campaign.type == 'onAction') {
          this.campaign.action_event_list = this.campaign.action_event_list ? JSON.parse(this.campaign.action_event_list) : '';
        }

        // Render utm content if found.
        if (this.campaign.utm_tracking_config) {
          setTimeout(() => {
            this.$refs.utmInput.render(JSON.parse(this.campaign.utm_tracking_config));
          }, 500);
        }

        // Read target audiance
        this.targetAudiance = response.data.audiance;

        this.fetchingData = false;
        requestAnimationFrame(() => {
          this.hasUnsavedChanges = false;
        });
      } catch (err) {
        this.reportError(err);
        this.errorToast('Failed to read campaign review details. Please contact support.');
        this.fetchingData = false;
      }
    },

    async fetchSegmentList() {
      let result = await getSegmentList(true);
      return result.data;
    },

    async fetchStaticSegmentList() {
      let result = await getAllStaticSegments(true);
      return result.data;
    },

    async onGoBack() {
      this.$router.push(`/campaign/update/goals/${this.campaignId}`);
    },

    async onPublish() {
      try {
        await this.$swal({
          title: 'Start Campaign?',
          text: "You won't be able to make any changes to running campaign. You won't be able to undo this!",
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#67C23A',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Start Campaign'
        });
      } catch (err) {
        // Cacelled, Ignore
        return;
      }

      try {
        this.startingCampaign = true;
        await publishCampaign(this.campaign.id);

        // Report event.
        this.marketingTrackEvent('Campaign Published', {
          'Campaign Id': this.campaign.id,
          Channel: this.campaign.channel,
          'Track Revenue': this.campaign.track_revenue,
          'Start Type': this.campaign.start_type,
          'Follow DND': this.campaign.follow_dnd,
          'Follow Capping': this.campaign.follow_capping
        });

        // Redirect to campaign report page.
        this.successToast('Campaign Launched.');
        this.$router.push({
          name: 'CampaignReport',
          params: { id: this.campaign.id }
        });
      } catch (err) {
        this.reportError(err);
        this.errorToast('Failed to start campaign. Please contact support.');
        this.startingCampaign = false;
      }
    }
  },

  mounted() {
    this.campaignId = this.$route.params.id;
    if (this.campaignId == null) {
      // Redirect to 404
      this.$router.replace('/404');
      return;
    }
    this.fetchDetails();
  }
};
